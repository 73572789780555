import React from "react";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Home from "../Components/Home";
import About from "../Components/About";
import Services from "../Components/Services";
import Coures from "../Components/Courses";
import Price from "../Components/Price";
import Contact from "../Components/Contact";
import Blogs from "../Components/Blogs";
import Details from "../Components/Details";
import Help from "../Components/Help";
import PrivacyPolicy from "../Components/PrivacyPolicy";


const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/courses" element={<Coures />} />
        <Route path="/pricing" element={<Price />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/details" element={<Details />} />
        <Route path="/help" element={<Help />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
