import React, { useState } from 'react'
import { FACEBOOK_URL, LINKEDIN_URL } from '../constant/Constant';
import { appsAlert, isValidEmail, openLink, thankYouAlert } from '../helper/HelperUtils';
import { JOIN_US_API } from '../constant/ApiUtis';
import {useNavigate} from 'react-router-dom'

const Footer = () => {

  const [email,setEmail] = useState('');
  const navigate = useNavigate();

  const sendJoinRequest = async () => {
    if(email.trim().length == 0){
      appsAlert( "Please enter your email !");
    }else if(!isValidEmail(email)){
      appsAlert("Please enter your valid email !");
    } else {
    try {
      let response = await fetch(
        JOIN_US_API,
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
          }),
           headers: { "Content-type": "application/json" },
        }
      );
      if(response){
        setEmail('');
        thankYouAlert();
      }

    } catch (error) {
          console.log(error);
    }
  }
  }




  return (
    <>
      <footer className="footer section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="item">
                <a className="logo" href="/">
                  <img
                    src="/appscasa_logo.png"
                    alt="logo"
                    style={{ borderRadius: "10px" }}
                  />
                </a>
                <p>
                  We design and develop mobile apps that delight your users and
                  grow your business. Enterprise grade development combined with
                  outstanding design..
                </p>
                <div className="social">
                  <a href="#0" onClick={()=> {openLink(FACEBOOK_URL) } } className="icon">
                    <i className="fab fa-facebook-f" />
                  </a>
                  {/* <a href="#0" className="icon">
                    <i className="fab fa-twitter" />
                  </a> */}
                  <a href="#0" onClick={()=>{ openLink(LINKEDIN_URL)}} className="icon">
                    <i className="fab fa-linkedin-in" />
                  </a>
                  {/* <a href="#0" className="icon">
                    <i className="fab fa-behance" />
                  </a> */}
                </div>

              </div>
            </div>
            <div className="offset-lg-1 col-lg-3 col-md-3">
              {/* <div className="item">
                <h6>Useful Links</h6>
                <ul>
                  <li>
                    <a href="#0">
                      <i className="pe-7s-angle-right-circle" /> About Us
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="pe-7s-angle-right-circle" /> Features
                    </a>
                  </li>
                  <li>
                    <a  onClick={()=> {navigate('/help')}} href="#0">
                      <i className="pe-7s-angle-right-circle" /> Help
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="pe-7s-angle-right-circle" /> How It Works?
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="item">
                <h6>Join to appscasa</h6>
                <form>
                  <input
                    id="subscribe_email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Your Email"
                    onChange={(ev) => setEmail(ev.target.value)}
                  />
                  <button onClick={()=>{sendJoinRequest()}}   type='button' className="butn butn-bg">
                    <span>
                      Send <i className="pe-7s-paper-plane" />
                    </span>
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
        <div className="sub-footer text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Copyright © All Right Reserved By Appscasa Pvt. Ltd. - 2025
                </p>
              </div>
              <a href="#0" onClick={()=> {navigate('/privacy')}} className="text-info" aria-current="page">Privacy Policy</a>

            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer