import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <Helmet>
        <title>Privacy Policy - The Best Mobile App Developent Company</title>
        <meta name="description" content="Developing best and secure app has never been easier. You can contact us via phone and live chat for help."/>
      </Helmet>
      <Navbar />
      <div
        className="valign"
        data-overlay-dark={7}
       >
        <div className="container">
            <div className="full-width mt-100">
            </div>
        </div>
      </div>
     
      <div className="container mx-auto mb-4">
      <h6 className="text-3xl font-bold mb-2 mt-4">Privacy Policy</h6>
      <p>AppsCasa.com ("we," "our," or "us") is committed to protecting the privacy of our users. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of information for our website, AppsCasa.com.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Updates to the Privacy Policy</h6>
      <p>We reserve the right to update and modify this Privacy Policy at any time. Changes will be posted on this page, and continued use of our website signifies acceptance of any modifications.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Collection of Information</h6>
      <p>AppsCasa.com collects personal information when you request information about our products or services. The types of personal information we collect may include:</p>
      <ul className="list-disc pl-6 font-bold text-lg">
        <li>Name, Date of Birth, Address, Email Address, Gender, Designation, Company Name, Other relevant information</li>
       
      </ul>
      <p>Additionally, we may collect visitor logs, including IP addresses, country of access, and other related details.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Children’s Privacy</h6>
      <p>Children under the age of 13 are not permitted to request information or use our services.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Sharing of Information</h6>
      <p>AppsCasa.com does not share, rent, or disclose your personal information to any third party except:</p>
      <ul className="list-disc pl-6">
        <li>Our employees, subcontractors, or partners as necessary to provide support or services.</li>
      </ul>
      
      <h6 className="text-2xl font-semibold mt-4">Editing of Information</h6>
      <p>You can request modifications to your personal information by emailing us at <a href="mailto:support@appscasa.com" className="text-primary">support@appscasa.com</a> or contacting your designated Account Manager at AppsCasa.com.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Support</h6>
      <p>We provide support via email and a client support desk. All information provided is password-protected and accessible only to authorized employees, subcontractors, or partners offering technical or other support.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Security</h6>
      <p>We implement security measures to protect your personal information from loss, misuse, and unauthorized access. Access to personal data is restricted to employees, subcontractors, and partners who require it to serve you.</p>
      
      <h6 className="text-2xl font-semibold mt-4">Contact Us</h6>
      <p>For any questions regarding this Privacy Policy, please contact us at <a href="mailto:support@appscasa.com" className="text-primary">support@appscasa.com</a>.</p>
    </div>
    {/* <Footer /> */}

    </>
  );
};

export default PrivacyPolicy;
